import { Component, OnInit, ViewChild, HostBinding, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav, MatDialog } from '@angular/material';
import { ResizeService } from '../../../services/resize.service';
import { routerAnimation } from '../../../utils/page.animation';
import { AuthService } from '../../../services/auth.service';
import { DbService } from '../../../services/db.service';
import { Modules } from '../../../models/modules';
import { User } from '../../../models/user';

@Component({
  selector: 'app-main-user',
  templateUrl: './main-user.component.html',
  styleUrls: ['./main-user.component.scss'],
  animations: [routerAnimation]
})
export class MainUserComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = true;
  modules:Modules
  @ViewChild('comments') sidenavComments: MatSidenav;
  @HostBinding('class.dark-theme') darkTheme = false;
  _sidenavMode = 'side';
  _boxedLayout = false;
  sideNavOpened = false;
  heightView = window.innerHeight - 50;
  feedbackComments = null
  name = "Insights"
  user:User = {
    username: "Carregando...",
    userName: "Carregando...",
    avatar_url: "assets/avatars/user-icon.jpg",
    email: "Carregando..."
  }

  constructor(public resizeService: ResizeService, 
              public auth:AuthService, 
              public db:DbService, 
              public dialog:MatDialog,
              public router:Router) {
    db.modulesObj().subscribe(modules => this.modules = modules)
    this.name = db.projectName
    document.querySelector('body').classList.add(this.db.projectId);
  }

  ngOnInit() {
    this.auth.af.authState.subscribe(user => {
      if(user){        
        this.db.userObj(user.uid).subscribe(user => this.user = user)
      }else{
        this.router.navigate(['/login'])
      }
    })
    this.onResize();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
    setTimeout(() => this.sideNavOpened = true, 0);
  }

  logout(){
    this.auth.logout()
  }

  close() {
    this.feedbackComments = null;
    this.sidenavComments.close();
  }

  set sidenavMode(val) {
    this._sidenavMode = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get sidenavMode() {
    return this._sidenavMode;
  }

  set boxedLayout(val) {
    this._boxedLayout = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get boxedLayout() {
    return this._boxedLayout;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.heightView = window.innerHeight - 50
    if (window.innerWidth < 800) {
      this.sideNavOpened = false;
      this._sidenavMode = 'over';
    }
  }
}