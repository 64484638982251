import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { NgxEchartsModule } from 'ngx-echarts';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { AppComponent } from './app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MainUserComponent } from './pages/main/main-user/main-user.component';
import { MainAdminComponent } from './pages/main/main-admin/main-admin.component';
import { PerformanceComponent } from './pages/performance/performance/performance.component';
import { LoginForgotPasswordComponent } from './pages/login/login-forgot-password/login-forgot-password.component';
import { SafePipe } from './pipes/safe.pipe';
import { ROUTES } from './app.routes';
import { SidemenuModule } from './modules/sidemenu/sidemenu.module';
import { ResizeModule } from './modules/resize.module';
import { environment } from '../environments/environment';
import { DbService } from './services/db.service';
import { RefService } from './services/ref.service';
import { AuthService } from './services/auth.service';
import { UploadService } from './services/upload.service';
import { LoginLinkComponent } from './pages/login/login-link/login-link.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateSharedModule } from './modules/translate.module';

@NgModule({
  declarations: [
    AppComponent,
    MainUserComponent,
    MainAdminComponent,
    LoginComponent,
    LoginForgotPasswordComponent,
    SafePipe,
    DashboardComponent,
    PerformanceComponent,
    PrivacyPolicyComponent,
    LoginLinkComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    OverlayModule,
    PortalModule,
    SidemenuModule,
    NgxEchartsModule,
    ResizeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateSharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    RouterModule.forRoot(ROUTES, {preloadingStrategy: PreloadAllModules}),
  ],
  providers: [
    DbService,
    RefService,
    AuthService,
    UploadService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
