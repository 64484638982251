import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routerAnimation } from '../../utils/page.animation';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { DbService } from '../../services/db.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerAnimation]
})
export class LoginComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = true;
  logo = `/assets/logo/${this.db.projectId}/logo.png`

  constructor(public auth:AuthService, 
              public router:Router,
              public db:DbService,
              private snack:MatSnackBar) {}

  ngOnInit() {
    this.auth.af.authState.subscribe(user => {
      if(user){
        this.db.userObj(this.auth.getUid()).pipe(take(1)).subscribe(user => {
          if(user.role != 'admin'){
            this.router.navigateByUrl('/');
          }else{
            this.router.navigateByUrl('/admin');
          }
        })
      }
    })
  }

  login (login, password:string) {
    this.auth.emailLogin(login,password.trim())
    .catch((error) => {
      switch(error.code){
        case "auth/user-not-found":
        this.snack.open('Não há registro de usuário correspondente a este email.',null,{duration:4000})
        break
        case "auth/invalid-email":
        this.snack.open('O endereço de e-mail está mal formatado.',null,{duration:4000})
        break
        case 'auth/wrong-password':
        this.snack.open('A senha é inválida.',null,{duration:4000})
        break
        default:this.snack.open('Ocorreu um erro! :'+error,null,{duration:5000})
      }
    })
  }
}
