import { Component, OnInit } from '@angular/core';
import { ResizeService } from '../../../services/resize.service';
import { DbService } from '../../../services/db.service';
import { AuthService } from '../../../services/auth.service';
declare let echarts: any;

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})

export class PerformanceComponent implements OnInit {

  option
  radar
  moods
  date
  chartInstances = [];
  resizeSubscription;

  constructor(private db:DbService,private auth:AuthService, private resizeService: ResizeService) {
    this.resizeSubscription = resizeService.resizeInformer$.subscribe(
      () => this.chartInstances.forEach((chart) => chart.resize())
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.date = new Date()
      this.auth.af.authState.subscribe(user => {
        if(user){
          this.db.ref.statisticsCompetenciesUsersFeedbacks(user.uid).on('value',competencies => {
              if(competencies.exists()){
                  let indicadores = []
                  let values = []
                  competencies.forEach(competence => {
                    console.log(competence.val())
                    indicadores.push({text: competence.val().name, max: 1})
                    values.push(competence.val().score)
                    return false
                  })
                  this.radar = {
                    title : {
                        text: '',
                        subtext: ''
                    },
                    tooltip : {
                        trigger: 'axis'
                    },
                    legend: {
                        orient : 'vertical',
                        x : 'right',
                        y : 'bottom',
                      //   data:['预算分配（Allocated Budget）','实际开销（Actual Spending）']
                    },
                    toolbox: {
                        show : true,
                        feature : {
                          saveAsImage : {
                              show : true,
                              title : 'save',
                              type : 'png',
                              // lang : ['点击保存']
                          }
                        }
                    },
                    radar : [
                        {
                        radius: 120,
                        indicator : indicadores
                        }
                    ],
                    calculable : true,
                    series : [
                        {
                            name: '',
                            type: 'radar',
                            itemStyle: {
                              normal: {
                                  // areaStyle: {
                                  //     type: 'default'
                                  // }
                                  areaStyle: {
                                    normal: {
                                        opacity: 0.9,
                                        color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                                            {
                                                color: '#B8D3E4',
                                                offset: 0
                                            },
                                            {
                                                color: '#72ACD1',
                                                offset: 1
                                            }
                                        ])
                                    }
                                  }
                              }
                            },
                            data : [
                                {
                                    value : values,
                                    name : ''
                                },
                            ]
                        }
                    ]
                  }
              }
          })
          this.db.statisticsUsersFeedbacksSentimentValueReceivedObj(user.uid).subscribe(snap => {
            console.log(snap)
            if(snap != null){
              this.option = {
                // title : {
                //     text: '南丁格尔玫瑰图',
                //     x:'center'
                // },
                color: ['#37a2da','#32c5e9','#9fe6b8','#ffdb5c','#ff9f7f','#fb7293','#e7bcf3','#8378ea'],
                tooltip : {
                    trigger: 'item',
                    // formatter: "{a} <br/>{b} : {c} ({d}%)"
                    formatter: "{b} : {c} ({d}%)"
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        // dataView : {show: true, readOnly: false},
                        magicType : {
                            show: true,
                            type: ['pie', 'funnel']
                        },
                        // restore : {show: true},
                        // saveAsImage : {show: true}
                        saveAsImage : {
                          show : true,
                          title : 'save',
                          type : 'png',
                          // lang : ['点击保存']
                      }
                    }
                },
                calculable : true,
                series : [
                    {
                        name:'增值电信业务统计表',
                        type:'pie',
                        radius : [40, 150],
                        roseType : 'area',
                        data:[
                            {value:snap['awsomeCount'], name:'Ótimo'},
                            {value:snap['goodCount'], name:'Bom'},
                            {value:snap['averageCount'], name:'Regular'},
                            {value:snap['badCount'], name:'Ruim'},
                            {value:snap['worstCount'], name:'Péssimo'}
                        ].sort(function (a, b) { return a.value - b.value; })
                    }
                ]
            };
            }
          //   this.option = {
          //     backgroundColor: '#FFFFFF',
          //     title: {
          //         text: '',
          //         left: 'center',
          //         top: 20,
          //         textStyle: {
          //             // color: '#ccc'
          //             color:'#000000'
          //         }
          //     },

          //     tooltip : {
          //         trigger: 'item',
          //         formatter: "{a} <br/>{b} : {c} ({d}%)"
          //     },

          //     visualMap: {
          //         show: false,
          //         min: 0,
          //         max: 100,
          //         // min: 80,
          //         // max: 600,
          //         inRange: {
          //             colorLightness: [0, 5]
          //         }
          //     },
          //     series : [
          //         {
          //             name:'Feedbacks Recebidos',
          //             type:'pie',
          //             radius : '55%',
          //             center: ['50%', '50%'],
          //             data:[
          //               {value:snap['awsomeCount'], name:'Otimo'},
          //               {value:snap['goodCount'], name:'Bom'},
          //               {value:snap['averageCount'], name:'Regular'},
          //               {value:snap['badCount'], name:'Ruim'},
          //               {value:snap['worstCount'], name:'Pessimo'}
          //                 // {value:335, name:'Otimo'},
          //                 // {value:310, name:'Bom'},
          //                 // {value:274, name:'Regular'},
          //                 // {value:235, name:'Ruim'},
          //                 // {value:400, name:'Pessimo'}
          //             ].sort(function (a, b) { return a.value - b.value; }),
          //             roseType: 'radius',
          //             label: {
          //                 normal: {
          //                     textStyle: {
          //                         // color: '#ccc'//'rgba(255, 255, 255, 0.3)'
          //                         color: '#9e9e9e'//'rgba(255, 255, 255, 0.3)'
          //                     }
          //                 }
          //             },
          //             labelLine: {
          //                 normal: {
          //                     lineStyle: {
          //                         // color: '#ccc'// 'rgba(255, 255, 255, 0.3)'
          //                         color: '#9e9e9e'// 'rgba(255, 255, 255, 0.3)'
          //                     },
          //                     smooth: 0.2,
          //                     length: 10,
          //                     length2: 20
          //                 }
          //             },
          //             itemStyle: {
          //                 normal: {
          //                     color: '#c23531',
          //                     shadowBlur: 200,
          //                     shadowColor: 'rgba(0, 0, 0, 0.5)'
          //                 }
          //             },
          //             animationType: 'scale',
          //             animationEasing: 'elasticOut',
          //             animationDelay: function (idx) {
          //                 return Math.random() * 200;
          //             }
          //         }
          //     ]
          // };
          })
          this.db.moodsList(user.uid).subscribe(moods => {
            if(moods.length != 0){
              let label = []
              let data = []
              for(let mood of moods){
                label.push(mood['textMood'])
                data.push(mood['value'])
              }
              this.moods = {
              toolbox: {
                show : true,
                feature : {
                  saveAsImage : {
                    show : true,
                    title : 'save',
                    type : 'png',
                  }
                }
              },
              calculable : true,
              xAxis : [
                {
                  type : 'category',
                  boundaryGap : false,
                  data : label
                }
              ],
              yAxis : [
                {
                  type : 'value'
                }
              ],
              series : [
                  {
                    type:'line',
                    smooth:true,
                    itemStyle: {normal: {areaStyle: {type: 'default'}}},
                    data: data
                  }
              ]
              }
            }
          })
        }
      })
    }, 0);
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }
}
