// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyALszHrLOcsNVj0nnBP-P7aw9_ouXdYe30",
    authDomain: "fiesc-insights.firebaseapp.com",
    databaseURL: "https://fiesc-insights.firebaseio.com",
    projectId: "fiesc-insights",
    storageBucket: "fiesc-insights.appspot.com",
    messagingSenderId: "761158549907",
    appId: "1:761158549907:web:2d92fbb795d9d4a1"
  }
};
